<template>
  <div class="matchList">
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
    <skelet-rectangle :width="10000" :height="82" />
  </div>
</template>

<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";
export default {
  name: "matchesListSkeleton",
  components: {SkeletRectangle}
}
</script>

<style scoped>

</style>