<template>
  <div class="matchCard">
    <div class="t1">
      <div class="logo"><img :src="getFirstParticipant.attributes.logo" alt="#"></div>
      <div class="name">{{getFirstParticipant.type === 'teams' ? getFirstParticipant.attributes.name : getFirstParticipant.attributes.login}}</div>
      <div class="score" :class="isFirstWinner">{{getFirstScore}}</div>
    </div>
    <div class="data">
      <span class="s1">{{ getMatchStatus }}</span>
    </div>
    <div class="t2">
      <div class="score" :class="isSecondWinner">{{getSecondScore}}</div>
      <div class="name">{{getSecondParticipant.type === 'teams' ? getSecondParticipant.attributes.name : getSecondParticipant.attributes.login}}</div>
      <div class="logo"><img :src="getSecondParticipant.attributes.logo" alt="#"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import setVictoryOnPoints from "@/otherJS/setVictoryOnPoints";

export default {
  name: "match-card",
  props: {
    props: {}
  },
  computed: {
    ...mapGetters([
        'PARTICIPANTS'
    ]),
    getFirstParticipant() {
      return this.PARTICIPANTS.find(item => Number(item.id) === Number(this.props.attributes.participantable_first_id))
    },
    getSecondParticipant() {
      return this.PARTICIPANTS.find(item => Number(item.id) === Number(this.props.attributes.participantable_second_id))
    },
    getMatchStatus() {
      let status = this.props.attributes.status;
      return status === 'no_started' ? 'Ожидается' : status === 'finished' ? 'Завершён' : 'В процессе'
    },
    isFirstWinner() {
      if (this.props.attributes.status === 'finished') {
        if(Number(this.props.attributes.first_score) > Number(this.props.attributes.second_score)) {
          return 'win';
        }
      }
      return ''
    },
    isSecondWinner() {
      if (this.props.attributes.status === 'finished') {
        if(Number(this.props.attributes.first_score) < Number(this.props.attributes.second_score)) {
          return 'win';
        }
      }
      return ''
    },

    getFirstScore() {
      return setVictoryOnPoints(this.props, 'first')
    },

    getSecondScore() {
      return setVictoryOnPoints(this.props, 'second')
    }
  },
}
</script>

<style scoped>

</style>