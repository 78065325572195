<template>
  <div class="matchList">
    <router-link
        class="match-card__wrapper"
        v-for="match in getPlayedMatches"
        :to="{ name: 'SingleMatch', params: { id: match.id }}"
        :key="match.id">
      <match-card :props="match"/>
    </router-link>

    <div ref="triggerBlock" class="triggerBlockForMatches" v-if="!allMatchesIsLoaded">
      <spinner/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MatchCard from "@/components/tournaments/singleTournament/matches/match-card";
import Spinner from "@/components/UIElements/spinner";
import isElementInViewport from "@/otherJS/isElementInViewport";


export default {
  name: "matchList",
  props: {
    isInfiniteScrollStopped: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      observer: null,
    }
  },
  components: {Spinner, MatchCard},
  computed: {
    ...mapGetters([
      'MATCHES',
      'GET_MATCHES_META'
    ]),

    getPlayedMatches() {
      return this.MATCHES.filter(item => {
        if (item.attributes['participantable_first_id'] !== null
            && item.attributes['participantable_second_id'] !== null
        ) return true
      })
    },

    allMatchesIsLoaded() {
      //Возвращаем true, потому что в месте, где используется это значение - оно инвертируется
      if (this.isInfiniteScrollStopped) {
        return true
      }
      console.log({scroll: this.isInfiniteScrollStopped})
      return this.GET_MATCHES_META.page['last-page'] === this.GET_MATCHES_META.page['current-page']
    }
  },
  methods: {
    ...mapActions([
      'SET_MORE_MATCHES'
    ])
  },

  updated() {
    // Если будет такая ситуация, что в матчи вмещаются в окно браузера, то Intersection Observer
    // сработает только один раз, а спиннер и будет дальше крутится.
    // Именно для такого случая я узнаю, что если спиннер находится в поле зрения
    // браузера (целый спиннер, а не только его часть), то получай ещё данные с бэкенда.
    // Это будет продолжаться до тех пор, пока спиннер не выйдет за область браузера

    if (isElementInViewport(this.$refs.triggerBlock)) {
      this.SET_MORE_MATCHES();
    }
  },

  destroyed() {
    // Когда компонент размонтируется из DOM - Intersection observer будет уничтожен.
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  mounted() {
    if (!this.allMatchesIsLoaded) {
      // Как только наш компонент имплементировался в DOM - создаем для него Intersection Observer
      const target = document.querySelector('.triggerBlockForMatches');

      // Создаем коллбэк для обсёрвера. Он будет триггерится на спиннер
      const callback = (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            this.SET_MORE_MATCHES();
          }
        }
      };
      const options = {
        root: null,
        threshold: 0,
        rootMargin: '0px',
      };

      // Инициализируем обсёрвер и запускаем
      this.observer = new IntersectionObserver(callback, options);
      this.observer.observe(target);
    }
  },
}
</script>

<style lang="scss" scoped>
.triggerBlockForMatches {
  margin-top: 30px;
}
</style>
