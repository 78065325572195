const isElementInViewport = (observableElement) => {

    const rect = observableElement?.getBoundingClientRect();

    if (!rect) return false

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

export default isElementInViewport;