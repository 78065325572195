<template>
  <div class="matchesWrapper" v-if="isMatches">
    <!-- TODO Пока тут только один параметр фильтрации, то показывает весь блок по участию пользователя в турнире.
          Когда появится больше фильтров, "v-if="TOURNAMENT.participant_entry !== null"" нужно будет переместить на "<label class="check">"
     -->
    <div ref="filters_block" class="matches-header" v-if="TOURNAMENT.participant_entry !== null">
      <label class="check">
        <input ref="check" type="checkbox" @click.stop="getMyMatches">
        <span class="rect">
          <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.09961L5.2 8.89961L1 4.69961" stroke="#EAEAEA" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </span>
        <span class="text">С моим участием</span>
      </label>
    </div>

    <div class="tabsContent">
      <match-list v-if="isMatchesList" :isInfiniteScrollStopped="isMyMatchesShowed"/>
      <matches-list-skeleton v-else />
    </div>
  </div>
  <div v-else>
    <spinner />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";
import MatchList from "@/components/tournaments/singleTournament/matches/matchList";
import MatchesListSkeleton from "@/views/sceleton/matchesListSkeleton";


export default {
  name: "tournamentMatches",
  components: {MatchesListSkeleton, MatchList, Spinner},
  data() {
    return {
      isMatches: false,
      isMatchesList: false,
      isMyMatchesShowed: false
    }
  },
  computed: {
    ...mapGetters([
      'TOURNAMENT',
      'PARTICIPANTS',
      'USER'
    ]),
  },
  watch: {
    isMyMatchesShowed: async function(newVal) {
      this.isMatchesList = false;
      if (newVal) {
        await this.GET_MATCHES_BY_USER({
          id: this.TOURNAMENT.id,
          userId: this.USER.id
        })
        this.isMatchesList = true;
        this.$refs.filters_block.scrollIntoView({block: 'start', behavior: 'smooth'})

        return
      }

      await this.GET_UPCOMING_MATCHES({id: this.TOURNAMENT.id})
      this.$refs.filters_block.scrollIntoView({block: 'start', behavior: 'smooth'})
      this.isMatchesList = true;
    }
  },
  methods: {
    ...mapActions([
      'GET_UPCOMING_MATCHES',
      'GET_MATCHES_BY_USER'
    ]),
    getMyMatches() {
      this.isMyMatchesShowed = !this.isMyMatchesShowed;
    }
  },

  async mounted() {
    await this.GET_UPCOMING_MATCHES({id: this.TOURNAMENT.id})
    this.isMatches = true;
    this.isMatchesList = true;
  }
}
</script>

<style scoped lang="scss">
.matchesWrapper {
  padding-top: 36px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 992px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 525px) {
    padding-top: 5px;
  }
}

.tabsContent {
  width: 100%;
  @media (max-width: 525px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

</style>
